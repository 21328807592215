import React from 'react';
import SEO from '../components/seo';
import { HomeLayout, FirstGridItem } from '../components/homeLayout';
import { Link, graphql } from 'gatsby';
import { rhythm } from '../utils/typography';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Image from 'gatsby-image'

class HomePage extends React.Component {
    render () {
        const { data } = this.props;
        const posts = data.allAirtable.edges
        library.add(fab)

        return (
            <React.Fragment>
                <HomeLayout>
                    <FirstGridItem>
                        <div style={{maxWidth: '580px', paddingTop: '4rem'}}>
                        <Image
                            fixed={data.avatar.childImageSharp.fixed}
                            alt="codebyomar-logo"
                            style={{
                                marginRight: rhythm(1 / 2),
                                marginBottom: 0,
                                minWidth: 50,
                                borderRadius: '100%'
                            }}
                        />
                        <p style={{color:'#606F7B'}}>
                            Hello! I'm Umar Abdullahi, I'm a full Stack Developer, i build stuff
                            that work on computers and mobile devices. I have worked with some startups
                            as well as some well established companies. I blog about modern technologies too.
                        </p>
                        <p style={{color:'#606F7B'}}>
                            Building for the next billion users in my own little space. i'm currently not available 
                            for freelance work.
                        </p>

                        <span style={{fontWeight: 900, display: 'block', marginBottom: '10px'}}>
                           Get in touch
                        </span>
                        <div style={{color:'#8d8bf4', marginBottom: '20px'}}>
                            omarmohammed580@gmail.com
                        </div>

                        <a 
                          style={{padding:'10px 10px 10px 0px',color:'#666', boxShadow:'none', cursor:'pointer'}}
                          href="https://twitter.com/codebyomar">
                            <FontAwesomeIcon icon={{iconName:"twitter", prefix:"fab"}} size="2x"/>
                        </a>
                        
                        <a 
                          style={{padding:'10px', boxShadow:'none', color:'#666', cursor:'pointer'}}
                          href="https://github.com/codebyomar">
                             <FontAwesomeIcon icon={{iconName:"github", prefix:"fab"}} size="2x"/>
                        </a>

                        <a 
                          style={{padding:'10px', boxShadow:'none', color:'#666', cursor:'pointer'}}
                          href="https://www.linkedin.com/in/umar-abdullahi-789a2b130/">
                             <FontAwesomeIcon icon={{iconName:"linkedin", prefix:"fab"}} size="2x"/>
                        </a>
                        </div>
                    </FirstGridItem>
                </HomeLayout>

                <div style={{ maxWidth: rhythm(35), padding: '4rem 2rem 6rem 18%',}}>
                    <span style={{fontWeight: 900, display: 'block', marginBottom: '10px'}}>
                         Recent posts
                    </span>
                    {posts.slice(0, 4).map(({ node }) => {
                        const title = node.data.title || node.data.slug
                        return (
                            <div key={node.data.slug} style={{marginBottom: rhythm(3/4)}}>
                                <small>{node.data.date}</small>
                                <span style={{ marginBottom: rhythm(0), display: 'block' }}>
                                    <Link style={{ boxShadow: 'none' }} to={node.data.slug}>
                                        {title}
                                    </Link>
                                </span>
                            </div>
                        )
                    })}
                    <Link to='/blog'>View all posts</Link>
                </div>
            </React.Fragment>
        )
    }
}

export default HomePage;

export const pageQuery = graphql`
  query {
    avatar:file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
            fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
            }
        }
    }
    allAirtable(sort: { fields: [data___date], order: DESC }) {
      edges {
          node {
            data {
              title
              slug
              description
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
    }
  }
`
