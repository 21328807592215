import React from 'react';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';


export function HomeLayout(props) {
    const keywords = ['blog', 'software engineer', 'javascript', 'react', 'golang', 'author', 'Umar Abdullahi', 'node.js', 'go nuggets', 'codebyomar'];
    const {children} = props;
    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            padding: '4rem 2rem 4rem 18%',
            marginBottom: 0,
            gridGap: 50,
            backgroundColor: '#F4F5F6'
        }}>
            <SEO title="codebyomar" {...{ keywords }}/>
            {children}
        </div>
    )
}

export function FirstGridItem(props) {
    const {children} = props;
    return (
        <div style={{ 
            gridRowStart: 1, 
            gridRowEnd: 4,
        }}>
           { children }
        </div>
    )
}